import React from 'react'
import Layout from '../layout/Layout'
import Banner from '../components/Banner'
import GroupComp from '../components/GroupComp'
import Image from '../assets/group-dtalks.png'
import ImageColor from '../assets/DTalksColor.jpg'
const DTalks = () => {
    const BannerData = {
        Heading: <h1>Unzipped With<br/><span>Dtalks!</span></h1>
    }

    const GroupData = {
        Image: Image,
        ImageColor: ImageColor,
        Heading: "Dtalks Media",
        Text: "Dtalks is a community where curiosity is celebrated and topics unspoken of are shared in a fun and relatable way. As an entertainment hub, Dtalks brings together a vibrant mix of engaging content, from interactive shows to thought-provoking discussions. Our platform is designed to entertain and enlighten, making it easy for members to explore and enjoy a wide range of subjects. At Dtalks, every segment is created to discover something new and exciting."
    }
    return (
        <Layout>
            <Banner data={BannerData} />
            <div className='container'>
                <div className='width80 ptb70'>
                    <GroupComp data={GroupData}/>
                </div>
            </div>
        </Layout>
    )
}

export default DTalks