import React from 'react'
// import Facebook from '../assets/SocialIcons/facebook.png'
// import Instagram from '../assets/SocialIcons/instagram.png'
import Twitter from '../assets/SocialIcons/twitter.png'
import Logo from '../assets/E9Llogo.png'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate()

    return (
        <div className='footer'>
            <div className='width80 content'>
                <div className='copy-right'>
                    <p>© Elastic Email Inc.2024</p>
                </div>
                <div className='widget'>
                    <div className='social-icons'>
                        {/* <a href='https://www.facebook.com/Evenzo9' target='_blank' rel="noreferrer">
                            <img src={Facebook} />

                        </a>
                        <a href='https://www.instagram.com/evenzo9official/?hl=en' target='_blank' rel="noreferrer">
                            <img src={Instagram} />
                        </a> */}
                        <a href='https://www.linkedin.com/company/e9holdings' target='_blank' rel="noreferrer">
                            <img src={Twitter} />
                        </a>

                    </div>
                    <div className='logo'>
                        <img src={Logo} onClick={() => {
                            navigate('/')
                            window.scrollTo(0, 0)
                        }} />
                    </div>


                </div>

            </div>

        </div>
    )
}

export default Footer