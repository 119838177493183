import React from 'react'
import Layout from '../layout/Layout'
import Banner from '../components/Banner'
import Map from '../assets/map.png'
import LocationIcon from '../assets/location.png'

const ContactUs = () => {
    const BannerData = {
        Heading: <h1><span>Contact </span>Us!</h1>
    }
    return (
        <Layout>
            <Banner data={BannerData} />
            <div className='container'>
                <div className='width80 map-container ptb70' >
                    <a href='https://maps.app.goo.gl/mAGRAZgcsFPthoUX6' target='_blank' rel="noreferrer" style={{position:"relative"}}>
                    <div className='map-image'>
                        <img src={Map} />
                    </div>

                    <div className='location'>
                        <h2>get in touch</h2>
                        <div className='address'>
                            <img src={LocationIcon} />
                            <div className='details'>
                                <h3>address:</h3>
                                <p>FOB51169 Compass Building, Ras Al Khaimah, U.A.E</p>
                            </div>
                        </div>
                    </div>
                    </a>
                </div>
            </div>
        </Layout>
    )
}

export default ContactUs