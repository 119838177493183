import { Menu } from 'antd'
import { useNavigate } from 'react-router-dom'
// import { TbLayoutDashboard } from 'react-icons/tb'

const MainMenu = ({ active }) => {
  const navigate = useNavigate()

  return (
    <Menu
      theme='dark'
      mode={'inline'}
      defaultSelectedKeys={[active]}
      style={{
        background: 'transparent',
        minHeight: '83vh',
      }}
    >
      <Menu.SubMenu
        title='Group'
        key='group'
        className={'sidebar-menu'}
      >
        <Menu.Item onClick={() => navigate('/hanker')}>HANKER</Menu.Item>
        <Menu.Item onClick={() => navigate('/evenzo9')}>EVENZO9</Menu.Item>
        <Menu.Item onClick={() => navigate('/shematters')}>SHEMATTERS</Menu.Item>
        <Menu.Item onClick={() => navigate('/d-talks')}>DTALKS MEDIA</Menu.Item>
      </Menu.SubMenu>
      <Menu.Item
        key='clients'
        className={'sidebar-menu'}
        // icon={<TbLayoutDashboard className='menu-icon' />}
        onClick={() => navigate('/clients')}
      >
        CLIENTS
      </Menu.Item>
      <Menu.Item
        key='about'
        className={'sidebar-menu'}
        // icon={<TbLayoutDashboard className='menu-icon' />}
        onClick={() => navigate('/about-us')}
      >
        ABOUT
      </Menu.Item>
      <Menu.Item
        key='contact'
        className={'sidebar-menu'}
        // icon={<TbLayoutDashboard className='menu-icon' />}
        onClick={() => navigate('/contact-us')}
      >
        CONTACT
      </Menu.Item>
    </Menu>
  )
}

export default MainMenu
