import React from 'react'
import Layout from '../layout/Layout'
import Banner from '../components/Banner'
import GroupComp from '../components/GroupComp'
import Hooker from '../assets/Hooker.png'
import HookerColor from '../assets/HookerColor.jpg'
const Hanker = () => {
    const BannerData = {
        Heading: <h1>The All-In-One
            Platform Built To
            Optimize <span> Marketing
                & Communication</span></h1>
    }

    const GroupData = {
        Image: Hooker,
        ImageColor: HookerColor,
        Heading: "hanker",
        Text: "Hanker is an all-in-one platform built to optimize marketing and communication. Hanker&apos;s cutting-edge products and services provide a comprehensive solution for businesses, revolutionizing the world of marketing and technology. Hanker is transforming the technology landscape through cutting-edge solutions that drive innovation across diverse industries, including Telecom, Retail, Fashion, Banking, E-commerce, Lifestyle, and Healthcare. By redefining operational paradigms and enhancing customer engagement, Hanker sets new standards of excellence and efficiency in each sector.",
        Link: "https://hankerglobal.com"
    }
    return (
        <Layout>
            <Banner data={BannerData} />
            <div className='container'>
                <div className='width80 ptb70'>
                    <GroupComp data={GroupData}/>
                </div>
            </div>
        </Layout>
    )
}

export default Hanker