import React, { useState } from 'react'
import { Col, Row } from 'antd'
import Hands from '../assets/hands.png'
import Dtalks from '../assets/aboutDtalks.jpg'
import Pexels from '../assets/pexels-anna.png'
import PexelsColor from '../assets/pexels-anna-color.jpg'
import HandsColor from '../assets/handsColor.jpg'

const AboutComp = () => {
    const [Color, setColor] = useState('')
    return (
        <div className='container about'>
            <div className='width80'>
                <Row gutter={[20, 20]}>
                    <Col xs={24} md={24} lg={16}>
                        <div className='image' onMouseOver={()=>{setColor('hands')}} onMouseOut={()=>{setColor('')}}>
                            <img src={Color === 'hands' ? HandsColor : Hands } />
                        </div>
                    </Col>
                    <Col xs={24} md={24} lg={8}>
                        <div className='l-content'>
                            <h2>success thorugh <span>innovation</span></h2>
                            <p>E9 is a dynamic holding company with diverse investments spanning Technology, Fintech, Digital Innovation, Media and Fashion & Retail.</p>
                        </div>
                    </Col>
                    <Col xs={24} lg={7}>
                        <div className='dtalks'>
                            <img src={Dtalks} />
                        </div>
                    </Col>
                    <Col xs={24} lg={7}>
                        <div className='r-content'>
                            <p>We specialize in fostering innovation and growth across these verticals, driving success through strategic ventures.</p>
                            <p>Our relentless pursuit of excellence propels us to lead and transform industries, driving exceptional growth and setting new benchmarks for success.</p>
                        </div>
                    </Col>
                    <Col xs={24} lg={10}>
                        <div className='pexels' onMouseOver={()=>{setColor('pexel')}} onMouseOut={()=>{setColor('')}}>
                            <img src={Color === 'pexel' ? PexelsColor : Pexels } />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default AboutComp