import React, { useState } from 'react'
import Layout from '../layout/Layout'
import Banner from '../components/Banner'
import Descon from '../assets/Clients/1-Descon.jpg'
import DesonColor from '../assets/Clients/1-DesconColor.jpg'
import kashmir from '../assets/Clients/2-Kashmir.jpg'
import KashmirColor from '../assets/Clients/2-KashmirColor.jpg'
import Molty from '../assets/Clients/3-MasterMoltyFoam.jpg'
import MoltyColor from '../assets/Clients/3-MasterMoltyFoamColor.jpg'
import Metro from '../assets/Clients/4-Metro.jpg'
import MetroColor from '../assets/Clients/4-MetroColor.jpg'
import Chughtai from '../assets/Clients/5-Chugtai Lab.jpg'
import ChughtaiColor from '../assets/Clients/5-Chugtai LabColor.jpg'
import Coke from '../assets/Clients/cocacola.jpg'
import CokeColor from '../assets/Clients/6-coca colaColor.jpg'
import Servis from '../assets/Clients/7-Servis.jpg'
import ServisColor from '../assets/Clients/7-ServisColor.jpg'
import SanaSafinaz from '../assets/Clients/8-SanaSafinaz.jpg'
import SanaSafinazColor from '../assets/Clients/8-SanaSafinazColor.jpg'
import Starlet from '../assets/Clients/9-Starlet.jpg'
import StarletColor from '../assets/Clients/9-StarletColor.jpg'
import Engine from '../assets/Clients/10-Engine.jpg'
import EngineColor from '../assets/Clients/10-EngineColor.jpg'
import Carefour from '../assets/Clients/11-Carrefour.jpg'
import CarefourColor from '../assets/Clients/11-CarrefourColor.jpg'
import Logo from '../assets/Clients/12-Logo.jpg'
import LogoColor from '../assets/Clients/12-LogoColor.jpg'
import Ndure from '../assets/Clients/13-Ndure.jpg'
import NdureColor from '../assets/Clients/13-NdureColor.jpg'
import Ccl from '../assets/Clients/14-CCL.jpg'
import CclColor from '../assets/Clients/14-CCLColor.jpg'
import Rozee from '../assets/Clients/15-Rozee.jpg'
import RozeeColor from '../assets/Clients/15-RozeeColor.jpg'
import Fatima from '../assets/Clients/16-Fatima Group.jpg'
import FatimaColor from '../assets/Clients/16-FatimaGroupColor.jpg'
import Ptcl from '../assets/Clients/17-Ptcl.jpg'
import PtclColor from '../assets/Clients/17-PtclColor.jpg'
import Stylo from '../assets/Clients/18-Stylo.jpg'
import StyloColor from '../assets/Clients/18-StyloColor.jpg'
import Netsol from '../assets/Clients/19-Netsol.jpg'
import NetsolColor from '../assets/Clients/19-NetsolColor.jpg'
import Jockey from '../assets/Clients/20-Jockey.jpg'
import JockeyColor from '../assets/Clients/20-JockeyColor.jpg'
import Abacus from '../assets/Clients/21-Abacus.jpg'
import AbacusColor from '../assets/Clients/21-AbacusColor.jpg'
import Footlib from '../assets/Clients/22-Footlib.jpg'
import FootlibColor from '../assets/Clients/22-FootlibColor.jpg'
import MeriPharmacy from '../assets/Clients/23-MeriPharmacy.jpg'
import MeriPharmacyColor from '../assets/Clients/23-Meri PharmacyColor.jpg'
import Jazz from '../assets/Clients/24-Jazz.jpg'
import JazzColor from '../assets/Clients/24-JazzColor.jpg'
import AlFatah from '../assets/Clients/25-Al-Fatah.jpg'
import AlFatahColor from '../assets/Clients/25-Al-FatahColor.jpg'
import BrandBeat from '../assets/Clients/26-BrandBeat.jpg'
import BrandBeatColor from '../assets/Clients/26-Brand BeatColor.jpg'
import Pepsi from '../assets/Clients/27-Pepsi.jpg'
import PepsiColor from '../assets/Clients/27-PepsiColor.jpg'
import Lama from '../assets/Clients/28-Lama.jpg'
import LamaColor from '../assets/Clients/28-LamaColor.jpg'
import Reefland from '../assets/Clients/29-Reefland.jpg'
import ReeflandColor from '../assets/Clients/29-ReeflandColor.jpg'
import CoffeePlanet from '../assets/Clients/30-CoffeePlanet.jpg'
import CoffeePlanetColor from '../assets/Clients/30-Coffee PlanetColor.jpg'
import BSS from '../assets/Clients/31-Beaconhouse.jpg'
import BSSColor from '../assets/Clients/31-BeaconhouseColor.jpg'
import SadiaArshad from '../assets/Clients/32-SadiaArshad.jpg'
import SadiaArshadColor from '../assets/Clients/32-SadiaArshadColor.jpg'
import Lawrencepur from '../assets/Clients/33-Lawrencepur.jpg'
import LawrencepurColor from '../assets/Clients/33-LawrencepurColor.jpg'
import Vogue from '../assets/Clients/34-Vogue.jpg'
import VogueColor from '../assets/Clients/34-VogueColor.jpg'
import FoodPanda from '../assets/Clients/35-Foodpanda.jpg'
import FoodPandaColor from '../assets/Clients/35-FoodpandaColor.jpg'
import Ego from '../assets/Clients/36-Ego.jpg'
import EgoColor from '../assets/Clients/36-EgoColor.jpg'
import Kpmg from '../assets/Clients/37-KPMG.jpg'
import KpmgColor from '../assets/Clients/37-KPMGColor.jpg'
import Furor from '../assets/Clients/38-Furor.jpg'
import FurorColor from '../assets/Clients/38-FurorColor.jpg'
import Mendeez from '../assets/Clients/39-Mendeez.jpg'
import MendeezColor from '../assets/Clients/39-MendeezColor.jpg'
import Insignia from '../assets/Clients/40-Insignia.jpg'
import InsigniaColor from '../assets/Clients/40-InsigniaColor.jpg'
import Filli from '../assets/Clients/41-Filli.jpg'
import FilliColor from '../assets/Clients/41-FilliColor.jpg'
import Nirala from '../assets/Clients/42-Nirala.jpg'
import NiralaColor from '../assets/Clients/42-NiralaColor.jpg'
import Edenrobe from '../assets/Clients/43-Edenrobe.jpg'
import EdenrobeColor from '../assets/Clients/43-EdenrobeColor.jpg'
import Nestle from '../assets/Clients/44-Nestle.jpg'
import NestleColor from '../assets/Clients/44-NestleColor.jpg'
import Monark from '../assets/Clients/45-Monark.jpg'
import MonarkColor from '../assets/Clients/45-MonarkColor.jpg'
import Astore from '../assets/Clients/46-Astore.jpg'
import AstoreColor from '../assets/Clients/46-AstoreColor.jpg'
import Hum from '../assets/Clients/47-HUMTV.jpg'
import HumColor from '../assets/Clients/47-HUMTVColor.jpg'
import Marito from '../assets/Clients/48-Marito.jpg'
import MaritoColor from '../assets/Clients/48-MaritoColor.jpg'
import Lg from '../assets/Clients/49-LG.jpg'
import LgColor from '../assets/Clients/49-LGColor.jpg'
import Rollover from '../assets/Clients/50-Rollover.jpg'
import RolloverColor from '../assets/Clients/50-RolloverColor.jpg'
import Declare from '../assets/Clients/51-Declare.jpg'
import DeclareColor from '../assets/Clients/51-DeclareColor.jpg'
import Dynasty from '../assets/Clients/52-Dynasty.jpg'
import DynastyColor from '../assets/Clients/52-DynastyColor.jpg'
import Bandana from '../assets/Clients/53-Bandana.jpg'
import BandanaColor from '../assets/Clients/53-BandanaColor.jpg'
import GloriaJeans from '../assets/Clients/54-GloriaJeans.jpg'
import GloriaJeansColor from '../assets/Clients/54-GloriaJeansColor.jpg'
import Ace from '../assets/Clients/55-Ace.jpg'
import AceColor from '../assets/Clients/55-AceColor.jpg'
import Hanker from '../assets/Clients/56-Hanker.jpg'
import HankerColor from '../assets/Clients/56-HankerColor.jpg'
import Forge from '../assets/Clients/57-TheForge.jpg'
import ForgeColor from '../assets/Clients/57-TheForgeColor.jpg'
import Zain from '../assets/Clients/58-Zain.jpg'
import ZainColor from '../assets/Clients/58-ZainColor.jpg'
import HouseOfLuggage from '../assets/Clients/59-InternationalHouseofLuggage.jpg'
import HouseOfLuggageColor from '../assets/Clients/59-InternationalHouseofLuggageColor.jpg'

const Clients = () => {
  const [Color, setColor] = useState('')

  const BannerData = {
    Heading: <h1>Our Group Partners & <span>Clients</span></h1>
  }
  return (
    <Layout>
      <Banner data={BannerData} />
      <div className='container'>
        <div className='width80 ptb70' >
          <div className='clients-container'>
            <div className='client' onMouseOver={()=>{setColor('descon')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'descon' ? DesonColor : Descon} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('kashmir')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'kashmir' ? KashmirColor : kashmir} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('molty')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'molty' ? MoltyColor : Molty} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('metro')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'metro' ? MetroColor : Metro} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('chughtai')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'chughtai' ? ChughtaiColor : Chughtai} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('coke')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'coke' ? CokeColor : Coke} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('servis')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'servis' ? ServisColor : Servis} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('sanasafinaz')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'sanasafinaz' ? SanaSafinazColor : SanaSafinaz} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('starlet')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'starlet' ? StarletColor : Starlet} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('engine')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'engine' ? EngineColor : Engine} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('carefour')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'carefour' ? CarefourColor : Carefour} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('logo')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'logo' ? LogoColor : Logo} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('ndure')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'ndure' ? NdureColor : Ndure} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('ccl')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'ccl' ? CclColor : Ccl} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('rozee')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'rozee' ? RozeeColor : Rozee} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('fatima')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'fatima' ? FatimaColor : Fatima} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('ptcl')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'ptcl' ? PtclColor : Ptcl} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('stylo')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'stylo' ? StyloColor : Stylo} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('netsol')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'netsol' ? NetsolColor : Netsol} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('jockey')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'jockey' ? JockeyColor : Jockey} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('abacus')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'abacus' ? AbacusColor : Abacus} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('footlib')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'footlib' ? FootlibColor : Footlib} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('pharmacy')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'pharmacy' ? MeriPharmacyColor : MeriPharmacy} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('jazz')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'jazz' ? JazzColor : Jazz} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('alfatah')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'alfatah' ? AlFatahColor : AlFatah} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('brandbeat')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'brandbeat' ? BrandBeatColor : BrandBeat} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('pepsi')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'pepsi' ? PepsiColor : Pepsi} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('lama')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'lama' ? LamaColor : Lama} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('reefland')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'reefland' ? ReeflandColor : Reefland} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('coffeeplanet')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'coffeeplanet' ? CoffeePlanetColor : CoffeePlanet} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('bss')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'bss' ? BSSColor : BSS} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('sadiaarshad')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'sadiaarshad' ? SadiaArshadColor : SadiaArshad} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('lawrencepur')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'lawrencepur' ? LawrencepurColor : Lawrencepur} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('vogue')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'vogue' ? VogueColor : Vogue} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('foodpanda')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'foodpanda' ? FoodPandaColor : FoodPanda} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('ego')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'ego' ? EgoColor : Ego} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('kpmg')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'kpmg' ? KpmgColor : Kpmg} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('furor')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'furor' ? FurorColor : Furor} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('mendeez')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'mendeez' ? MendeezColor : Mendeez} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('insignia')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'insignia' ? InsigniaColor : Insignia} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('filli')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'filli' ? FilliColor : Filli} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('nirala')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'nirala' ? NiralaColor : Nirala} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('edenrobe')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'edenrobe' ? EdenrobeColor : Edenrobe} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('nestle')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'nestle' ? NestleColor : Nestle} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('monark')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'monark' ? MonarkColor : Monark} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('astore')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'astore' ? AstoreColor : Astore} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('hum')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'hum' ? HumColor : Hum} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('marito')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'marito' ? MaritoColor : Marito} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('lg')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'lg' ? LgColor : Lg} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('rollover')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'rollover' ? RolloverColor : Rollover} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('declare')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'declare' ? DeclareColor : Declare} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('dynasty')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'dynasty' ? DynastyColor : Dynasty} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('bandana')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'bandana' ? BandanaColor : Bandana} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('gloria')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'gloria' ? GloriaJeansColor : GloriaJeans} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('ace')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'ace' ? AceColor : Ace} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('hanker')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'hanker' ? HankerColor : Hanker} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('forge')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'forge' ? ForgeColor : Forge} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('zain')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'zain' ? ZainColor : Zain} />
            </div>
            <div className='client' onMouseOver={()=>{setColor('luggage')}} onMouseOut={()=>{setColor('')}}>
              <img src={Color === 'luggage' ? HouseOfLuggageColor : HouseOfLuggage} />
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default Clients