import React from 'react'
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd'
import Logo from '../assets/E9Llogo.png'
import { useNavigate } from 'react-router-dom'
import MenuDrawer from '../layout/MenuDrawer';
const Header = ({active}) => {
  const navigate = useNavigate()

  const items = [

    {
      key: '1',
      label: (
        <p className='sub-menus'
          onClick={() => navigate('/hanker')}
        >
          hanker
        </p>
      ),
    },
    {
      key: '2',
      label: (
        <p className='sub-menus'
          onClick={() => navigate('/evenzo9')}
        >
          evenzo9
        </p>
      ),
    },
    {
      key: '3',
      label: (
        <p className='sub-menus'
          onClick={() => navigate('/shematters')}
        >
          shematters
        </p>
      ),
    },
    {
      key: '4',
      label: (
        <p className='sub-menus'
          onClick={() => navigate('/d-talks')}
        >
          dtalks media
        </p>
      ),
    },
  ];
  

  return (
    <>
      <div className='main-header'>
        <div className='width90 nav-bar'>
          <div className='logo'>
            <img src={Logo} onClick={() => navigate('/')} />
          </div>
          <div className='menus'>
            <Dropdown
              menu={{
                items,
              }}
            //   open={true}
            >
              <p onClick={(e) => e.preventDefault()}>
                group
                <DownOutlined />
              </p>
            </Dropdown>
            <p onClick={() => navigate('/clients')}>clients</p>
            <p onClick={() => navigate('/about-us')}>about</p>
            <p onClick={() => navigate('/contact-us')}>contact</p>
          </div>

        </div>
      </div>
      <div className='mobile-header'>
        <div className='width90'>

        <MenuDrawer active={active} />
        </div>
        {/* <Switch
          className='themeSwitch'
          defaultChecked={theme === 'light' ? false : true}
          checkedChildren={<MdDarkMode style={{ fontSize: '20px', marginRight: '5px' }} />}
          unCheckedChildren={<MdOutlineDarkMode style={{ fontSize: '20px', marginLeft: '5px' }} />}
          onChange={() => dispatch(toggleTheme())}
        /> */}
      </div>
    </>
  )
}

export default Header