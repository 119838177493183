import { Button, Col, Row } from 'antd'
import React, { useState } from 'react'
// import Image from '../assets/Hooker.png'
const GroupComp = ({data}) => {
  const [Color, setColor] = useState(false)

    return (
        <div className='container groups'>
            <Row gutter={[40, 20]}>
                <Col xs={24} md={24} lg={12}>
                    <div className='image' onMouseOver={()=>{setColor(true)}} onMouseOut={()=>{setColor(false)}}>
                        <img src={Color ? data?.ImageColor : data?.Image} />
                    </div>
                </Col>
                <Col xs={24} md={24} lg={12}>
                    <div className='content'>
                        <h3>{data?.Heading}</h3>
                        <p>{data?.Text}</p>
                        <a href={data?.Link} target='_blank' rel="noreferrer" style={{maxWidth: 'fit-content'}}>
                            <Button>Go to website {">"}</Button>

                        </a>

                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default GroupComp