import { Col, Row } from 'antd'
import React, { useState } from 'react'
// import Omer from '../assets/omer-farooq.png'

const BossComp = ({data, isReverse}) => {
  const [Color, setColor] = useState(false)
    const {Image, Name, Post, Message, ImageColor} = data
    return (
        <div className='boss-container ptb70'>
            <Row gutter={[20, 20]} style={{flexDirection: !isReverse?"row-reverse": "initial"}}>
                <Col xs={24} md={24} lg={10}>
                    <div className='image' onMouseOver={()=>{setColor(true)}} onMouseOut={()=>{setColor(false)}}>
                        <img src={Color ? ImageColor : Image} />
                    </div>
                </Col>
                <Col xs={24} md={24} lg={14}>
                    <div className='content'>
                        <h2>{Name}</h2>
                        <h5>{Post}</h5>
                        <p>{Message}</p>
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default BossComp