import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'

import Dashboard from '../pages/Dashboard'
import Login from '../pages/Login'
import Hanker from '../pages/Hanker'
import Evenzo from '../pages/Evenzo'
import Shematters from '../pages/Shematters'
import DTalks from '../pages/DTalks'
import AboutUs from '../pages/AboutUs'
import Clients from '../pages/Clients'
import ContactUs from '../pages/ContactUs'
// import Portfolio from '../pages/Portfolio'
// import PrivateWrapper from './PrivateRoutes'

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Uncommit next line to apply token security */}
        {/* <Route element={<PrivateWrapper />}> */}
        <Route path='/' element={<Dashboard />} />
        <Route path='/hanker' element={<Hanker/>} />
        <Route path='/evenzo9' element={<Evenzo/>} />
        <Route path='/shematters' element={<Shematters/>} />
        <Route path='/d-talks' element={<DTalks/>} />
        <Route path='/about-us' element={<AboutUs/>} />
        <Route path='/clients' element={<Clients/>} />
        <Route path='/contact-us' element={<ContactUs/>} />

        {/* <Route path='/portfolio' element={<Portfolio />} /> */}

        <Route path='*' element={<Navigate to='/' />} />
        {/* </Route> */}

        <Route path='/login' element={<Login />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Routers
