import React, { useState } from 'react'
import { Col, Row } from 'antd'
import Fashion from '../assets/Fashion.png'
import FashionColor from '../assets/FashionColor.jpg'
import Technology from '../assets/Technology.png'
import TechnologyColor from '../assets/TechnologyColor.jpg'
import Media from '../assets/media.png'
import MediaColor from '../assets/mediaColor.jpg'
import Digital from '../assets/DigitalInnovation.png'
import DigitalColor from '../assets/Digital-InnovationColor.jpg'

const PortfolioComp = () => {
  const [Color, setColor] = useState('')
  return (
    <div className='portfolio' >
      <Row gutter={[20, 20]}>
        <Col xs={24} md={24} lg={12} >
          <div className='box' style={{ backgroundImage: `url(${Color === 'fashion' ? FashionColor : Fashion })` }} onMouseOver={()=>{setColor('fashion')}} onMouseOut={()=>{setColor('')}}>
            <div className='black-box'>
              <h3>fashion</h3>
            </div>
          </div>
        </Col>
        <Col xs={24} md={24} lg={12}>
          <Row gutter={[20, 20]}>
            <Col xs={24} md={24} lg={12}>
              <div className='s-box' style={{ backgroundImage: `url(${Color === 'tech' ? TechnologyColor : Technology })` }} onMouseOver={()=>{setColor('tech')}} onMouseOut={()=>{setColor('')}}>
                <div className='black-box'>
                  <h3>technology</h3>
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div className='s-box' style={{ backgroundImage: `url(${Color === 'media' ? MediaColor : Media })` }} onMouseOver={()=>{setColor('media')}} onMouseOut={()=>{setColor('')}}>
                <div className='black-box'>
                  <h3>media</h3>
                </div>
            </div></Col>
            <Col xs={24} >
              <div className='s-box' style={{ backgroundImage: `url(${Color === 'digital' ? DigitalColor : Digital })` }} onMouseOver={()=>{setColor('digital')}} onMouseOut={()=>{setColor('')}}>
                <div className='black-box' >
                  <h3>digital <br/> innovation</h3>
                </div>
            </div></Col>
          </Row>
          {/* <div className='flex-box'>
            <div className='s-box' style={{ backgroundImage: `url(${Technology})` }}>
              <div className='black-box'>
                <h3>technology</h3>
              </div>
            </div>
            <div className='s-box' style={{ backgroundImage: `url(${Media})` }}>
              <div className='black-box'>
                <h3>media</h3>
              </div>
            </div>
          </div>
          <div className='s-box' style={{ backgroundImage: `url(${Digital})` }}>
            <div className='black-box' >
              <h3>digital innovation</h3>
            </div>
          </div> */}

        </Col>
      </Row>



    </div >
  )
}

export default PortfolioComp
