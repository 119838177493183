import React from 'react'
import Layout from '../layout/Layout'
import Banner from '../components/Banner'
import GroupComp from '../components/GroupComp'
import Image from '../assets/group-evenzo.png'
import ImageColor from '../assets/evenzoColor.jpg'
const Evenzo = () => {
    const BannerData = {
        Heading: <h1>Creating <br/>
            <span>Realities!</span></h1>
    }

    const GroupData = {
        Image: Image,
        ImageColor: ImageColor,
        Heading: "Evenzo9",
        Text: "Igniting exponential growth through cutting-edge strategies and innovative creative solutions. We are a growth hacking digital Innovation that transforms ideas into impactful results, leveraging data-driven insights and unconventional approaches to propel your business to new heights. For 7 years we have blended strategy and creativity to deliver exceptional work rooted in embracing the ever changing market dynamics. This synergy has guided our cross border expansion, where Evenzo9 has built a reputation for innovative and culturally nuanced campaigns, that solves problems in new creative ways for brands in new markets as well.",
        Link: "https://evenzo9.com"
    }
    return (
        <Layout>
            <Banner data={BannerData} />
            <div className='container'>
                <div className='width80 ptb70'>
                    <GroupComp data={GroupData}/>
                </div>
            </div>
        </Layout>
    )
}

export default Evenzo