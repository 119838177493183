import React from 'react'
import Layout from '../layout/Layout'
import Banner from '../components/Banner'
import GroupComp from '../components/GroupComp'
import Image from '../assets/group-shematters.png'
import ImageColor from '../assets/ShemattersColor.jpg'
const Shematters = () => {
    const BannerData = {
        Heading: <h1>adding your voice to the<span> Orchestra of life</span></h1>
    }

    const GroupData = {
        Image: Image,
        ImageColor: ImageColor,
        Heading: "shematters",
        Text: "Enablement is at the heart of SheMatters. Through targeted initiatives, the platform will champion women in all areas – from launching dream businesses to achieving educational goals, prioritizing health, and promoting diversity in the workplace. SheMatters isn't just about celebrating success, it's about propelling it.",
        Link: "https://shemattersofficial.com"
    }
    return (
        <Layout>
            <Banner data={BannerData} />
            <div className='container'>
                <div className='width80 ptb70'>
                    <GroupComp data={GroupData}/>
                </div>
            </div>
        </Layout>
    )
}

export default Shematters