import React from 'react'
import Layout from '../layout/Layout'
import Banner from '../components/Banner'
import BossComp from '../components/BossComp'
import Omar from '../assets/omer-farooq.png'
import Syma from '../assets/syma-arshad.png'
import Arshad from '../assets/mian-arshad.png'
import OmarColor from '../assets/omarColor.png'
import SymaColor from '../assets/SymaColor.png'
import ArshadColor from '../assets/ArshadColor.png'

const AboutUs = () => {
    const BannerData = {
        Heading: <h1><span>About </span>Us!</h1>
    }

    const BossData = [
        {
            Image: Omar,
            ImageColor: OmarColor,
            Name: "m. omar farooq",
            Post: "chief executive officer",
            Message: "As a visionary CEO, Mohammad Omar Farooq is a leader renowned for his entrepreneurial prowess and transformative impact on the retail landscape through data and technology. With over 20 years of experience, he has driven significant change and innovation, earning 16 prestigious awards and ensuring 184 satisfied clients. His strategic leadership in tech-based companies such as Hanker Technologies and Evenzo9 highlights his ability to scale businesses and spearhead advancements in the Retail Tech sector. Omar’s success in turning around organizations and his influential role on the boards of notable retail brands underscore his exceptional capability to lead and revolutionize the industry."
        },
        {
            Image: Syma,
            ImageColor: SymaColor,
            Name: "syma arshad",
            Post: "managing director",
            Message: "Syma, a digital and transformation leader with over 18 years of extensive experience, excels in aligning businesses with strategic priorities. Her deep insights into optimizing operations and maximizing business potential across the Telecom, Entertainment, Fashion, Retail, and Financial sectors are a testament to her ability to drive innovative solutions. Syma’s agile approach and adept problem-solving skills are complemented by her commitment to driving growth and redefining industry standards. Her leadership, marked by a relentless pursuit of excellence and transformative marketing strategies, has garnered global recognition and numerous awards, underscoring her significant influence and impact in advancing business success and innovation. Her entrepreneurial approach and strategic vision have led to successful brand expansions and market disruptions, showcasing her ability to deliver exceptional results and redefine industry standards."
        },
        {
            Image: Arshad,
            ImageColor: ArshadColor,
            Name: "mian muhammad arshad",
            Post: "Group Chairman",
            Message: "With over five decades of successful business ventures, Mian Muhammad Arshad, Group Chairman, is a distinguished serial entrepreneur renowned for his visionary leadership. His diverse portfolio includes new brand launches, retail, media, real estate, and electronics, with key partnerships involving global giants like LG, Philips, and Sony. Mian Arshad has driven exponential growth for numerous businesses, both locally and internationally, with notable ventures such as Ravi, Prism, and Peridot receiving international acclaim. His hands-on approach and profound grasp of the business landscape bring exceptional leadership and strategic insight to the E9 Group."
        },
    ]

    return (
        <Layout>
            <Banner data={BannerData} />
            <div className='container'>
                <div className='width80'>
                    {
                        BossData?.map((data, i) => {
                            const isReverse = i % 2 == 0
                            return <BossComp data={data} key={i} isReverse={isReverse} />
                        })
                    }
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs