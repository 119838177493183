import Layout from '../layout/Layout'
import Banner from '../components/Banner'
import NewsComp from '../components/NewsComp'
import Image1 from '../assets/News1.jpg'
import Image2 from '../assets/News2.jpg'
import Image3 from '../assets/News3.jpg'
import PortfolioComp from '../components/PortfolioComp'
import AboutComp from '../components/AboutComp'

const Dashboard = () => {
  const NewsData = [
    {
      Image: Image1,
      Text: 'Visionary Leader behind E9 Group, Syma Arshad Awarded with Global Leadership Award in UAE ',
      Link: 'https://www.linkedin.com/feed/update/urn:li:activity:7232654997736349696'
    },
    {
      Image: Image2,
      Text: 'Hanker Successfully partners with Jazz Pakistan',
      Link: 'https://www.linkedin.com/feed/update/urn:li:activity:7227621362645757952'
    },
    {
      Image: Image3,
      Text: 'Evenzo9 Partners with Filli Pakistan for their activation',
      Link: 'https://www.linkedin.com/feed/update/urn:li:activity:7233782717564256256'
    },
  ]
  const BannerData = {
    Heading: <h1>success <br /> through <br /> <span>innovation</span></h1>,
    Text: <p>E9 is a dynamic holding company with diverse investments spanning Technology, Fintech, Digital Innovation,
      Media and Fashion & Retail. We specialize in fostering innovation and growth across these verticals, driving
      success through strategic ventures. Our relentless pursuit of excellence propels us to lead and transform
      industries, driving exceptional growth and setting new benchmarks for success.</p>
  }
  return <Layout active={'dashboard'}>
    <Banner data={BannerData}/>
    <div className='container'>
      <div className='main-heading'>
        <div className='bbtm'>
          <h2>investment <span>Portfolio</span></h2>
        </div>
      </div>
      <div className='width70'>
        <PortfolioComp />
      </div>
      <div className='main-heading'>
        <div className='bbtm'>
          <h2>about <span>us</span></h2>
        </div>
      </div>
      <AboutComp />
      <div className='main-heading'>
        <div className='bbtm'>
          <h2><span>News</span></h2>
        </div>
      </div>
      <div className='width80 pb70'>
        {
          NewsData?.map((data, i) => (
            <NewsComp key={i} Text={data?.Text} Image={data?.Image} Link={data?.Link}/>

          ))
        }


      </div>


    </div>
  </Layout>
}

export default Dashboard
