import React from 'react'
import Header from './Header'

const Banner = ({data}) => {
    return (
        <div className='banner'>
            <Header />
            <div className='width90'>

                <div className='content'>

                    {data?.Heading}
                    {data?.Text}
                </div>

            </div>
        </div>
    )
}

export default Banner